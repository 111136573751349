<template>
  <div class="form-items" :class="{ skeleton: !state.loaded }">
    <div class="wrapper">
      <div class="head">
        <div class="title">항목 편집</div>
        <div class="side">
          <select class="form-control" v-model="state.step.id" @change="setItems()">
            <option :value="s.id" v-for="(s, idx) in state.step.list" :key="idx">{{ s.title }}</option>
          </select>
        </div>
      </div>
      <div class="body">
        <div class="row">
          <div class="items col-lg-6">
            <div class="form-group mb-0">
              <label :for="`${component.name}Items`" class="font-xs">항목</label>
              <FormMultiSelect :id="`${component.name}Items`" role="item" :title.sync="state.item.title" :items="state.item.list" :cloneable="true" :styles="{ minHeight: '523px' }" :key="state.item.key" :disabled="!state.loaded"/>
            </div>
          </div>
          <div class="detail col-lg-6" :data-type="computedItem.type">
            <div class="form-group">
              <label :for="`${component.name}Type`" class="font-xs">타입</label>
              <select :id="`${component.name}Type`" class="form-control" v-model="computedItem.type" @change="onTypeChange()">
                <option :value="t.name" v-for="t in form.item.types" :selected="t.name === 'text'">{{ t.title }}</option>
              </select>
              <div class="side">
                <label title="키 값으로 지정">
                  <input type="checkbox" class="hide" :checked="state.form.keyItemId && state.form.keyItemId === computedItem.id" @change="onKeyChange($event)"/>
                  <i class="fa fa-check"></i>
                  <span>KEY</span>
                </label>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <div class="form-group">
                  <label :for="`${component.name}Required`" class="font-xs">필수 값</label>
                  <select :id="`${component.name}Required`" class="form-control" v-model="computedItem.required">
                    <option :value="true" selected>필수</option>
                    <option :value="false">필수 아님</option>
                  </select>
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <label :for="`${component.name}HideTitle`" class="font-xs">항목 이름 숨김</label>
                  <select :id="`${component.name}HideTitle`" class="form-control" v-model="computedItem.hideTitle">
                    <option :value="false" selected>숨기지 않음</option>
                    <option :value="true">숨김</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="form-group">
              <label :for="`${component.name}Desc`" class="font-xs">설명</label>
              <textarea :id="`${component.name}Desc`" class="form-control" placeholder="선택 항목에 대한 설명" v-model="computedItem.description" @keyup.ctrl.enter="submit()"></textarea>
            </div>
            <div class="form-group">
              <label :for="`${component.name}Content`" class="font-xs">내용</label>
              <textarea :id="`${component.name}Content`" class="form-control" placeholder="선택 항목에 대한 내용" v-model="computedItem.content" @keyup.enter="submit()"></textarea>
            </div>
            <div class="form-group" v-if="optionTypes.includes(computedItem.type)">
              <label :for="`${component.name}Options`" class="font-xs">옵션</label>
              <FormMultiSelect :id="`${component.name}Options`" componentNameSuffix="Options" role="option" :items="computedItem.options" :styles="{ height: '92px' }"/>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="action">
      <button class="btn btn-purple btn-block" @click="submit()">저장하기</button>
    </div>
  </div>
</template>

<script>
import {computed, defineComponent, reactive} from "@vue/composition-api";
import mixin from "@/scripts/mixin";
import store from "@/scripts/store";
import http from "@/scripts/http";
import FormMultiSelect from "@/components/FormMultiSelect";
import lib from "@/scripts/lib";
import optionTypes from "@/texts/formItemOptionTypes.json";
import {httpError} from "@/scripts/httpError";
import form from "../scripts/form";

function Component(initialize) {
  this.name = "modalFormItems";
  this.initialize = initialize;
}

export default defineComponent({
  components: {FormMultiSelect},
  mixins: [mixin],
  setup() {
    const component = new Component(() => {
      store.commit("tuneModal", {component, size: "xm"});
      state.form.keyItemId = modalParams.keyItemId;

      state.loaded = false;
      http.get(`/api/admin/forms/${modalParams.formId}/steps`, {page: 0, size: 100}).then((res1) => {
        state.loaded = true;
        state.step.list = res1.data.body.content;

        if (state.step.list.length) {
          state.step.id = state.step.list[0].id;
          setItems();
        }
      });
    });

    const state = reactive({
      loaded: false,
      form: {
        keyItemId: null,
      },
      step: {
        id: null,
        list: [],
      },
      item: {
        title: "",
        loaded: false,
        key: 0,
        list: [],
        caches: {},
        origins: {}
      },
    });

    const modalParams = store.getters.modalParams(component);

    const computedItem = computed(() => {
      return state.item.list.find(i => i.delFlag === "N" && i.title === state.item.title) || {};
    });

    const onKeyChange = (e) => {
      if (e.target.checked) {
        state.form.keyItemId = computedItem.value.id;
      } else {
        state.form.keyItemId = null;
      }
    };

    const onTypeChange = () => {
      const item = computedItem.value;

      optionTypes.includes(item.type)
      && !item.options.length
      && item.options.push({
        id: null,
        title: `옵션 ${lib.getRandomNum(1000, 9999)}`,
        ord: 1,
        delFlag: "N"
      });
    };

    const setItems = () => {
      if (state.item.caches[state.step.id]) {
        state.item.list = state.item.caches[state.step.id];
        state.item.title = state.item.list[0].title;
        state.item.key += 1;
      } else {
        state.item.loaded = false;
        http.get(`/api/admin/forms/${modalParams.formId}/steps/${state.step.id}/items`, {page: 0, size: 100}).then((res2) => {
          state.item.loaded = true;

          state.item.list = res2.data.body.content;
          state.item.title = state.item.list[0].title;
          state.item.key += 1;

          state.item.caches[state.step.id] = state.item.list;
          state.item.origins[state.step.id] = lib.getRenewed(state.item.list);
        });
      }
    };

    const submit = () => {
      const args = [];

      for (let i in state.item.caches) {
        const items = state.item.caches[i];
        const origin = state.item.origins[i];

        if (JSON.stringify(items) === JSON.stringify(origin)) {
          continue;
        }

        for (let j = 0; j < items.length; j += 1) {
          items[j].stepId = window.Number(i);
          items[j].description = items[j].description?.trim();
          args.push(items[j]);
        }
      }

      http.put(`/api/admin/forms/${modalParams.formId}/keyItemId`, {value: state.form.keyItemId}).then(() => {
        http.put(`/api/admin/forms/${modalParams.formId}/step/items`, args).then(() => {
          store.commit("setSwingMessage", "항목을 저장하였습니다.");
          store.commit("closeModal", {
            name: component.name,
            onClose(modal) {
              store.dispatch("callback", {modal});
            }
          });
        }).catch(httpError((err) => {
          err?.response?.status === 500 && store.commit("setSwingMessage", "이미 신청 내역이 존재합니다. 항목 타입 변경 시 개발팀에 문의바랍니다.");
        }));
      });
    };

    return {component, state, form, optionTypes, modalParams, computedItem, onKeyChange, onTypeChange, setItems, submit,};
  }
});
</script>

<style lang="scss" scoped>
.form-items {
  > .wrapper {
    background: #fff;
    padding: $px25;
  }

  .head {
    position: relative;
    margin-bottom: $px20;

    .title {
      font-size: $px18;
    }

    .side {
      position: absolute;
      top: 0;
      right: 0;
      padding: 0;
      margin-top: $px-12;

      select {
        font-size: $px12;
        min-width: $px140;
        height: $px40;
        padding-top: $px7;
        padding-bottom: $px7;
      }
    }
  }

  .body {
    > .row {
      > div {
        .form-group {
          position: relative;
          font-size: 0;

          input, select, textarea {
            background-color: #fff;
            font-size: $px13;
            transition: none;
          }

          > .side {
            position: absolute;
            top: 0;
            right: 0;

            > a {
              font-size: 13px;
              cursor: pointer;
            }

            > label {
              display: flex;
              font-size: 12px;
              align-items: center;
              cursor: pointer;

              i {
                color: $colorPurple;
                display: none;
                margin-right: 5px;
              }

              input:checked + i {
                display: inline-block;
              }
            }
          }
        }

        &.detail {
          > .form-group:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  .action {
    position: sticky;
    bottom: 0;

    .btn {
      padding: $px15;
      height: $px60;
      border-radius: 0;
      font-size: $px13;

      &.remove {
        background: $colorSecondary;
        border-color: $colorSecondary;
        color: #fff;

        &:hover {
          background: $colorSecondaryActive;
          border-color: $colorSecondaryActive;
        }
      }
    }
  }

  &.skeleton {
    .head .side select {
      @include skeleton;
    }

    .body > .row > div .form-group {
      > .side > label {
        @include skeleton;
      }

      input, select, textarea {
        @include skeleton;
      }
    }
  }

  @media(max-width: 991px) {
    .body > .row > div.items {
      padding-bottom: 1.5rem;
    }
  }
}
</style>